<template>
  <div>
    <div class="max-w-430">
      <SmoothReflow tag="div" :options="{ ...heightReflowOptions, transitionEvent: { propertyName: 'opacity' } }">
        <Transition name="transition-fade-slow">
          <div v-if="emailSentSuccessfullyTo"
            class="flex items-center bg-success-light py-15 px-15 border border-gray-200 rounded-lg">
            <IconCheck class="h-50 w-50 text-success mr-15" />
            <p class="font-medium text-success-dark">
              {{ $t('auth_new_password.email_sent_confirmation') }}
              <span class="font-bold">
                {{ emailSentSuccessfullyTo }}
              </span>
              {{ $t('auth_new_password.email_sent_confirmation_end') }}
            </p>
          </div>
        </Transition>
      </SmoothReflow>
      <AuthForm :locale="$i18n.locale" :machine-config="requestNewPasswordMachineConfig" type="request-new-password"
        class="mt-20" @successful-submit="emailSentSuccessfullyTo = $event">
        {{ $t('auth_new_password.enter_email') }}
        <template #button-primary>
          <IconMail class="h-22 w-22 text-white mr-15" />
          {{ $t('auth_new_password.send') }}
        </template>
        <template #button-secondary>
          {{ $t('auth_new_password.to_login') }}
        </template>
      </AuthForm>
    </div>
  </div>
</template>


<script>
import IconMail from '@/components/icon/IconMail';
import IconCheck from '@/components/icon/IconCheck';
import AuthForm from '@/components/AuthForm';
import SmoothReflow from '@/components/SmoothReflow';
import { heightReflowOptions } from '@/helpers/reflowOptions';
import requestNewPasswordMachine from '@/machines/requestNewPasswordMachine';


export default {
  components: {
    IconMail,
    IconCheck,
    AuthForm,
    SmoothReflow,
  },
  data: () => ({
    heightReflowOptions: Object.freeze(heightReflowOptions),
    emailSentSuccessfullyTo: '',
    requestNewPasswordMachineConfig: Object.freeze(requestNewPasswordMachine),
  }),
};
</script>


<style>
.icon-check {
  & .primary {
    @apply fill-success;
  }

  & .secondary {
    @apply fill-success-light;
  }
}
</style>

export default {
  id: 'auth-request-new-password',
  context: {
    email: '',
    locale: '',
    serviceError: {
      code: '',
      message: '',
    },
  },
  initial: 'dataEntry',
  states: {
    dataEntry: {
      on: {
        EMAIL_INPUT: {
          actions: 'saveEmail',
        },
        EMAIL_BLUR: [
          {
            cond: 'emailIsEmpty',
            target: 'emailError.isEmpty',
          },
          {
            cond: 'emailFormatIsInvalid',
            target: 'emailError.invalidFormat',
          },
          {
            target: 'awaitingAvailableProviders',
          },
        ],
        SUBMIT: [
          {
            cond: 'emailIsEmpty',
            target: 'emailError.isEmpty',
          },
          {
            cond: 'emailFormatIsInvalid',
            target: 'emailError.invalidFormat',
          },
          {
            target: 'awaitingPasswordResetEmailResponse',
          },
        ],
      },
    },
    awaitingAvailableProviders: {
      invoke: {
        src: 'fetchSignInMethodsForEmail',
        onDone: [
          {
            cond: 'providerIsAvailable',
            target: 'dataEntry',
          },
          {
            cond: 'providerIsNotAvailable',
            target: 'emailError.accountNotFound',
          },
        ],
        onError: [
          {
            cond: 'serviceErrorInvalidEmailFormat',
            target: 'emailError.invalidFormat',
          },
          {
            actions: 'saveServiceError',
            target: 'serviceError',
          },
        ],
      },
    },
    awaitingPasswordResetEmailResponse: {
      // Make a call to the authentication service
      invoke: {
        src: 'sendPasswordResetEmail',
        // If successful, move to the signedIn state
        onDone: {
          target: 'requestedNewPassword',
        },
        onError: [
          {
            cond: 'serviceErrorInvalidEmailFormat',
            target: 'emailError.invalidFormat',
          },
          {
            cond: 'serviceErrorAccountNotFound',
            target: 'emailError.accountNotFound',
          },
          {
            actions: 'saveServiceError',
            target: 'serviceError',
          },
        ],
      },
    },
    emailError: {
      on: {
        EMAIL_INPUT: {
          actions: 'saveEmail',
          target: 'dataEntry',
        },
      },
      initial: 'invalidFormat',
      states: {
        isEmpty: {},
        invalidFormat: {},
        accountNotFound: {},
      },
    },
    serviceError: {
      on: {
        EMAIL_INPUT: {
          actions: 'saveEmail',
          target: 'dataEntry',
        },
        SUBMIT: {
          target: 'awaitingPasswordResetEmailResponse',
        },
      },
    },
    requestedNewPassword: {
      type: 'final',
    },
  },
};

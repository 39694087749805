<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :class="['icon-check', data.class, data.staticClass]"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      class="primary fill-transparent"
    />
    <path
      d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
      class="secondary fill-current"
    />
  </svg>
</template>


<script>
export default {};
</script>
